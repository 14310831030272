// ###########################################################################
// HEADER - Add class .min to scroll down
$(document).ready(function() {
  $(window).scroll(function() {
    // Calculate the scroll position
    var scrollPosition = $(window).scrollTop();

    // Check if the user has scrolled 300 pixels
    if (scrollPosition > 100) {
      // Add the 'min' class to the header
      $('.main-header').addClass('min');
    } else {
      // Remove the 'min' class if not scrolled 300 pixels
      $('.main-header').removeClass('min');
    }
  });
});

// ###########################################################################
// ANIMATION FADE TO TOP - element dom
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// ###########################################################################
// PRIMARY NAVIGATION
$("#btnMobile").click(function (e) {
  $(this).toggleClass("cross");
  $(".main-nav-wrapper").toggleClass("open");
  $("body").toggleClass("overflow");
});

// ###########################################################################
// SUBNAV NAVIGATION
$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    var LiParent =$(this).parent().attr('class');
    if(! LiParent.includes("openSub-menu") ){
        $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
    }
    $(this).parent().toggleClass("openSub-menu");
});

// CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".menu-item-has-children").removeClass("openSub-menu");
    }
});

// ###########################################################################
// ANCRE
// $('a[href^="#"]').on('click',function (e) {
//     e.preventDefault();

//     var nav = $('nav');
//     var target = this.hash,
//     $target = $(target);

//     $('html, body').stop().animate({
//         'scrollTop': $target.offset().top - nav.height()
//     }, 1200, 'swing', function () {
//         window.location.hash = target;
//     });
// });

// ###########################################################################





$(document).ready(function() {
  var slides = $('.slider .slide');
  var dots = $('.dotcontainer__dot');
  var currentIndex = 0;
  var isDragging = false;
  var startPosition = 0;
  var endPosition = 0;
  var autoSlideInterval;

  function switchSlide(nextIndex) {
      // Update current index
      currentIndex = nextIndex % slides.length;

      // Remove 'actif' class from all slides and dots
      slides.removeClass('actif');
      dots.removeClass('actif');

      // Add 'actif' class to current slide and dot
      slides.eq(currentIndex).addClass('actif');
      dots.eq(currentIndex).addClass('actif');
  }

  function startAutoSlide() {
      autoSlideInterval = setInterval(function() {
          if ($(window).width() < 576) {
              switchSlide(currentIndex + 1);
          }
      }, 5000);
  }

  function stopAutoSlide() {
      clearInterval(autoSlideInterval);
  }

  slides.mouseover(function() {
      stopAutoSlide(); // Stop auto sliding when mouseover
      currentIndex = slides.index(this);
      switchSlide(currentIndex);
      startAutoSlide(); // Restart auto sliding
  });

  dots.mouseover(function() {
      stopAutoSlide(); // Stop auto sliding when mouseover
      currentIndex = dots.index(this);
      switchSlide(currentIndex);
      startAutoSlide(); // Restart auto sliding
  });

  slides.on('mousedown touchstart', function(e) {
      isDragging = true;
      startPosition = e.pageX || e.originalEvent.touches[0].pageX;
      stopAutoSlide();
  });

  $(document).on('mousemove touchmove', function(e) {
      if (isDragging) {
          endPosition = e.pageX || e.originalEvent.touches[0].pageX;
      }
  });

  $(document).on('mouseup touchend', function(e) {
      if (isDragging) {
          isDragging = false;
          if (startPosition - endPosition > 50) {
              switchSlide(currentIndex + 1);
          } else if (startPosition - endPosition < -50) {
              switchSlide(currentIndex - 1);
          }
          startAutoSlide();
      }
  });

  $(window).resize(function() {
      // Restart auto sliding on window resize
      stopAutoSlide();
      startAutoSlide();
  });
  startAutoSlide(); //

});





$(document).ready(function() {
  gsap.registerPlugin(ScrollTrigger);

  // Fonction pour animer une star si elle existe dans le DOM
  function animateStarIfPresent(selector, x, y, scale, start) {
      if ($(selector).length) {
          gsap.to($(selector), {
              scrollTrigger: {
                  trigger: selector,
                  start: start,
                  end: "bottom top",
                  scrub: true,
              },
              x: x,
              y: y,
              scale: scale
          });
      }
  }

  // Appel de la fonction pour chaque star
  animateStarIfPresent(".star__01", -94, -54, 0.8, "top bottom");
  animateStarIfPresent(".star__02", -102, 31, 0.8, "top bottom");
  animateStarIfPresent(".star__03", -56, 146, 1.2, "top bottom");
  animateStarIfPresent(".star__04", 190, 40, 1.4, "top bottom");
  animateStarIfPresent(".star__05", 72, 52, 1.2, "top 22%");
  animateStarIfPresent(".star__06", 82, 22, 1.2, "top 20%");
});


$(document).ready(function() {
  // Vérifier si GSAP et ScrollTrigger sont chargés
  if (typeof gsap === 'undefined' || typeof ScrollTrigger === 'undefined') {
      console.error("GSAP or ScrollTrigger not loaded");
      return;
  }

  gsap.registerPlugin(ScrollTrigger);

  var path = document.querySelector('.maskPath');
  var pathLength = path.getTotalLength();

  path.style.strokeDasharray = pathLength;
  path.style.strokeDashoffset = pathLength;

  // Animation du path .lineMessage
  gsap.to(path, {
      scrollTrigger: {
          trigger: '.illustrationmessage__svg',
          start: 'bottom bottom',
          toggleActions: 'play none none none'
      },
      strokeDashoffset: 0,
      duration: 2,
      ease: 'power1.out',
      onComplete: animatePlane // Appel de la fonction animatePlane à la fin de l'animation
  });

  // Fonction pour animer .planeMessage
  function animatePlane() {
      gsap.to('.planeMessage', {
          opacity: 1,
          y: 0, // translateY de 0px
          x: 0,
          duration: 0.4,
          ease: 'power1.out'

      });
  }
});
